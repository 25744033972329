$(document).ready(() => {
    /**
     * Description: Method to make any container an accordion
     * Param: none
     * returns: none
     * requires: jQuery and accordion.scss on page
     * structure <container class="accordion"> with two children, accordion__exp (controls the toggle) and accordion__child (content to show)
     * contained styling is only for animations between classes.
     */
    function accordion() {
        $(document).unbind('click').on('click', '.accordion__exp', function (e) {
            // Hack to prevent anchors in triggers from firing accordion. Probably should have anchors that don't toggle in toggles, but YOLO - WJAY
            if (e.target.nodeName === 'A') {
                e.stopPropagation();
                return;
            }

            e.preventDefault();

            const isDesktop = window.matchMedia('(min-width: 992px)').matches;
            let activeClass;
            if (isDesktop) {
                activeClass = 'accordion--active';
            } else {
                activeClass = 'accordion--active--mobile';
            }

            const self = $(this);
            const parent = self.parents('.accordion');

            if (parent.hasClass(activeClass)) {
                parent.removeClass(activeClass);
            } else {
                parent.addClass(activeClass);
            }
            parent.focus();
        });
    }

    accordion();

    function checkOthers(elem) {
        document.querySelectorAll('.accordion-controls a.picks__expander').forEach((accordionElm) => {
            if (accordionElm !== elem) {
                if (accordionElm.getAttribute('aria-expanded') === 'true') {
                    accordionElm.setAttribute('aria-expanded', 'false');
                    const content = accordionElm.getAttribute('aria-controls');
                    document.getElementById(content).setAttribute('aria-hidden', 'true');
                    document.getElementById(content).classList.add('d-none');
                }
            }
        });
    }

    function accordionToggle() {
        document.querySelectorAll('.accordion-controls a.picks__expander').forEach((control) => {
            control.addEventListener('click', () => {
                const accordionContent = control.getAttribute('aria-controls');
                checkOthers(control[0]);

                const isAriaExp = control.getAttribute('aria-expanded');
                const newAriaExp = (isAriaExp === 'false') ? 'true' : 'false';
                control.getAttribute('aria-expanded', newAriaExp);
                const contentElm = document.getElementById(`${accordionContent}`);
                const isAriaHid = contentElm.getAttribute('aria-hidden');
                if (isAriaHid === 'true') {
                    contentElm.setAttribute('aria-hidden', 'false');
                    contentElm.classList.remove('d-none');
                } else {
                    contentElm.setAttribute('aria-hidden', 'true');
                    contentElm.classList.add('d-none');
                }
            });
        });
    }

    // call this function on page load
    accordionToggle();
});
